@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins-Regular";
  src: url("../public/fonts/Poppins-Regular.ttf");
}

option {
  color: #030712;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
  padding-right: 4px;
}

/* Chrome, Edge and Safari */
.custom-scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-track:active {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #c1c1c1;
}

.button-ok {
  width: 200px;
  height: Hug (43px) px;
  padding: 11px 20px 11px 20px;
  gap: 10px;
  border-radius: 10px;
  opacity: 0px;
  background-color: #26a0fc;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Lebar scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Thumb (pegangan) */
::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1);
  border-radius: 5px; /* Mengatur radius sudut thumb */
}

/* Thumb saat dihover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(131, 131, 131);
}

/* Thumb di ujung track */
::-webkit-scrollbar-thumb:horizontal:end,
::-webkit-scrollbar-thumb:vertical:end {
  border-bottom-right-radius: 5px; /* Radius sudut bawah kanan */
  border-top-right-radius: 5px; /* Radius sudut atas kanan */
}
